<template>
    <div>
        <PageTemplate>
            <template #hero>
                <HeroHome
                    :title="entry?.heroTitle || entry?.title "
                    :action-link="entry?.actionLink"
                    :images="entry?.images"
                />
            </template>
            <template #default>
                <ContentBlocks
                    v-if="entry"
                    :content-blocks="entry.contentBlocks"
                />
            </template>
        </PageTemplate>
    </div>
</template>

<script setup>
import EntryQuery from '~/graphql/queries/Entry.graphql';

const { variables: defaultVariables }  = useCraftGraphql();

const variables = computed(() => {
    return {
        ...defaultVariables,
        section: 'home',
        uri: undefined // We don't need the URI for this query
    };
});

const response = await useAsyncQuery({
    query: EntryQuery,
    variables
});

const { craftEntry: entry } = useCraftPage(response);
useCallToActions(response);
</script>
