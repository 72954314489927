<template>
    <header class="hero-home">
        <div class="hero-home__container">
            <hgroup
                v-if="!!props.title || !!$slots.title || !!$slots.titleElement"
                class="hero-home__title"
            >
                <slot name="titleElement">
                    <h1 v-if="props.title || !!$slots.title">
                        <slot name="title">{{ props.title }}</slot>
                    </h1>
                </slot>
            </hgroup>

            <ActionLink :link="actionLink" />
        </div>

        <div class="hero-home__ticker">
            <div
                v-for="(image, index) in images"
                :key="index"
                ref="boxes"
            >
                <BaseImage
                    :image="image"
                    loading="eager"
                    fetchpriority="high"
                />
            </div>
        </div>
    </header>
</template>

<script setup>
import gsap from 'gsap';

const props = defineProps({
    title: {
        type: String,
        default: null
    },
    actionLink: {
        type: Object,
        default: null
    },
    images: {
        type: Array,
        default: null
    }
});

const boxes = ref([]);
const loop = ref(null);
const progress = ref(0);

onMounted(() => {
    window.addEventListener('resize', build);
    
    setTimeout(() => {
        build();
    }, 50); // not sure why this works or is needed, but it does and it is
});

onUnmounted(() => {
    if(loop.value) {
        loop.value.kill();
    }
    window.removeEventListener('resize', build);
});

function build() {
    progress.value = loop.value ? loop.value.progress() : 0; // record the current progress so we can revert there (make it look seamless)
    loop.value && loop.value.progress(0).kill(); // on resize, we need to kill the old one and rebuild

    loop.value = horizontalLoop(boxes.value, {
        paused: false,
        repeat: -1,
        speed: .35,
        reversed: 1
    });
    loop.value.progress(progress.value);
    loop.value.reverse();
}

function horizontalLoop(items, config) {
    items = gsap.utils.toArray(items);
    config = config || {};

    const tl = gsap.timeline({repeat: config.repeat, paused: config.paused, defaults: {ease: 'none'}, onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)}),
        length = items.length,
        startX = items[0]?.offsetLeft,
        times = [],
        widths = [],
        xPercents = [],
        pixelsPerSecond = (config.speed || 1) * 100,
        snap = config.snap === false ? v => v : gsap.utils.snap(config.snap || 1); // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural

    let curIndex = 0,
        totalWidth, curX, distanceToStart, distanceToLoop, item, i;

    gsap.set(items, { // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
        // eslint-disable-next-line no-shadow
        xPercent: (i, el) => {
            const w = widths[i] = parseFloat(gsap.getProperty(el, 'width', 'px'));
            xPercents[i] = snap(parseFloat(gsap.getProperty(el, 'x', 'px')) / w * 100 + gsap.getProperty(el, 'xPercent'));
            return xPercents[i];
        }
    });
    gsap.set(items, {x: 0});

    // eslint-disable-next-line prefer-const
    totalWidth = items[length-1]?.offsetLeft + xPercents[length-1] / 100 * widths[length-1] - startX + items[length-1]?.offsetWidth * gsap.getProperty(items[length-1], 'scaleX') + (parseFloat(config.paddingRight) || 0);

    for (i = 0; i < length; i++) {
        item = items[i];
        curX = xPercents[i] / 100 * widths[i];
        distanceToStart = item?.offsetLeft + curX - startX;
        distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, 'scaleX');
        tl.to(item, {xPercent: snap((curX - distanceToLoop) / widths[i] * 100), duration: distanceToLoop / pixelsPerSecond}, 0)
            .fromTo(item, {xPercent: snap((curX - distanceToLoop + totalWidth) / widths[i] * 100)}, {xPercent: xPercents[i], duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond, immediateRender: false}, distanceToLoop / pixelsPerSecond)
            .add('label' + i, distanceToStart / pixelsPerSecond);
        times[i] = distanceToStart / pixelsPerSecond;
    }

    function toIndex(index, vars) {
        vars = vars || {};
        (Math.abs(index - curIndex) > length / 2) && (index += index > curIndex ? -length : length); // always go in the shortest direction
        const newIndex = gsap.utils.wrap(0, length, index);
        let time = times[newIndex];

        if (time > tl.time() !== index > curIndex) { // if we're wrapping the timeline's playhead, make the proper adjustments
            vars.modifiers = {time: gsap.utils.wrap(0, tl.duration())};
            time += tl.duration() * (index > curIndex ? 1 : -1);
        }
        curIndex = newIndex;
        vars.overwrite = true;
        return tl.tweenTo(time, vars);
    }

    tl.next = vars => toIndex(curIndex+1, vars);
    tl.previous = vars => toIndex(curIndex-1, vars);
    tl.current = () => curIndex;
    tl.toIndex = (index, vars) => toIndex(index, vars);
    tl.times = times;
    tl.progress(1, true).progress(0, true); // pre-render for performance
    if (config.reversed) {
        tl.vars.onReverseComplete();
        tl.reverse();
    }
    return tl;
}
</script>

<style lang="less" src="./HeroHome.less" />
